<template>
    <div class="server-son">
        <div class="server-son-1">
            <div>
                <div>
                    <div>百川智慧商展核心服务</div>
                    <div>
                        <div>区域协同</div>|<div>跨界融合</div>|<div>资源共享</div>
                    </div>
                </div>
                <div>
                    <img src="../../assets/about/1.png" />
                </div>
            </div>

        </div>
        <div class="server-son-2">
            <div class="tit">商业展示产业第三方服务</div>
            <div>
                <div v-for="item in datas" :key="item.title">
                    <div><img :src="item.img" /></div>
                    <div>{{ item.title }}</div>
                    <div style="text-align: start;">{{ item.content }}</div>
                </div>
            </div>
        </div>
        <div class="server-son-3">
            <div class="tit">提供品牌建店系统解决方案</div>
            <div><img style="width: 100%;" src="../../assets/about/111.jpg"/></div>
        </div>
        <footer-com></footer-com>
    </div>
</template>

<script>

export default {
    name: 'CoreServerSon',
    data() {
        return {
            datas: [
                {
                    img: require("../../assets/about/2.png"),
                    title: '企业认证服务',
                    content: '所有合作企业在经过国家资格专业认证的情况下蟒搬奠颠，惨彩崩再次经过百川实地考察后，根据百川产业认证体系标准，综合企业能力，匹配企业等级。'
                },
                {
                    img: require("../../assets/about/3.png"),
                    title: '材料认证服务',
                    content: '根据百川道具、材料认证体系标准，针对所合作国内品牌道具材料企业或厂家进行再次认证分类。'
                },
                {
                    img: require("../../assets/about/4.png"),
                    title: '技能认证服务',
                    content: '所有施工公司或个人，在通过国家专业认证基础上，仍需二次通过百川专业产业认证，根据百川产业体系认证后，根据实际工程需求进行匹配服务。'
                },
                {
                    img: require("../../assets/about/5.png"),
                    title: '产品认证服务',
                    content: '在国家、行业认证标准的基础上，根据百川产业认证体系做进一步产品规范,整合产业资源对产品做统一标准，书省损耗。'
                },
                {
                    img: require("../../assets/about/6.png"),
                    title: '人力资源认证服务',
                    content: '组织培训学校与机构，做好产品人才培训，为社会输送产业人才。在获得国家认证基础上，百川产业体系将对其进行二次专业认证。'
                },
            ]
        }
    },
    methods: {

    }
}
</script>

<style lang="scss">
.server-son {
    text-align: center;
    .tit {
        font-size: 3vw;
        background-image: linear-gradient(to right, #12929C, #419944);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        display: inline-block;
        margin: auto;
        padding: 5%;
    }

    .server-son-1 {
        background-image: linear-gradient(to right bottom, white, #F3F6FB);
        box-shadow: 5px 1px 5px #A8A9AB;

        >div {
            display: flex;
            height: 25vw;
            width: 80%;
            margin: auto;

            >div {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                box-sizing: border-box;
                gap: 8%;
                >div:nth-child(1) {
                    font-size: 3vw;
                }

                >div:nth-child(2) {
                    background-image: linear-gradient(to right, #47993F, #0D94A3);
                    box-sizing: border-box;
                    color: white;
                    >div {
                        padding: 0.5vw 0.7vw;
                        display: inline-block;
                        box-sizing: border-box;
                        font-size: 1vw;
                    }
                }

                >img {
                    height: 80%;
                }
            }

            >div:nth-child(1) {
                align-items: flex-start;
            }
        }
    }

    .server-son-2 {
        width: 80%;
        margin: auto;
        box-sizing: border-box;
        font-size: 1vw;
        >div:nth-child(2) {
            display: flex;
            justify-content: center;
            gap: 3%;
            flex-wrap: wrap;
            text-align: center;
            margin-bottom: -3%;
            >div {
                border: 1px solid #469947;
                flex: 1;
                padding: 1%;
                min-width: 200px;
                margin-bottom: 3%;
                >div:nth-child(2){
                    border-bottom: 1px solid #B3B3B3;
                    padding: 8% 0%;
                    color: black;
                    font-weight: bold;
                }
                >div:nth-child(3){
                    margin-top: 8%;
                    line-height: 1.5;
                    font-size: 0.9vw;
                }
            }
        }
    }
}
</style>